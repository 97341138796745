

import FETCHING from  '../../library/fetching'
import UMUM from  '../../library/umum'

export default {
  data() {
    return {

      form : {
        id : '',
        uraian : '',
        luas : '',
        tahun : '',
        des_kel_uniq : '',
        penggunaan : '',
        keterangan : '',
      },
     
      list_data : [],

      page_first: 1,
      page_last: 0,
      page_limit : 8,
      cari_value: "",
      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,

      FETCHING : FETCHING,
      UMUM : UMUM,
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_pengadaan_tanah + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              page_limit : this.page_limit
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jmlData;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },


    addData : function(number) {
      fetch(this.$store.state.url.URL_pengadaan_tanah + "addData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      fetch(this.$store.state.url.URL_pengadaan_tanah + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_pengadaan_tanah + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },
    selectData : function(data){

        this.form.id = data.id;
        this.form.uraian = data.uraian;
        this.form.luas = data.luas;
        this.form.tahun = data.tahun;
        this.form.des_kel_uniq = data.des_kel_uniq;
        this.form.penggunaan = data.penggunaan;
        this.form.keterangan = data.keterangan;

        this.tunggu(data.des_kel_uniq)
    },

    filterData : function (val, update, abort) {
            update(async () => {
              console.log(val);
              if (val === '') {}
              else {
                this.$store.state.listDesKel = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_DES_KEL + "autocomplete", {cari : val})
              }
            })
        },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },

        tunggu : async function(aa){
          this.$store.state.listDesKel = await FETCHING.FETCH_POST(this.$store.state.url.URL_MASTER_DES_KEL + "autocomplete", {cari : aa})
        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {
    this.tunggu('')
    this.getView();
  },
}
